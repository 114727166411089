

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
}

.bottombar {
  text-align: center;
  font-size: 250%;
  color: red;
  overflow-wrap: break-word;
}

.topbar {
  text-align: right;
  font-size: 3em;
}

.topbar #gold {
  color: gold;
}

.topbar #diamond {
  color: blue;
}

.topbar #menubutton {
  padding: 0 20px;
  background-color: lightblue;
  cursor: pointer;
}

#menu {
  width: 300px;
  height: 500px;
  background-color: lightblue;
  border: 1px solid black;
  position: absolute;
  right: 0;
}

#menu ul {
  padding-inline-start: 0;
  margin: 10px 0;
}

#menu li {
  display: block;
  list-style: none;
  text-align: left;
  font-size: 0.5em;
  border: 1px solid black;
  background-color: white;
  margin: 10px;
  padding: 10px;
  clear: both;
}

#menu .giveButton {
  float: right;
}

.goldValue {
  color: gold;
}

.diamondValue {
  color: blue;
}

.chocolateValue {
  color: brown;
}

.chickenValue{
  color: yellowgreen;
}

.bottombar {
  height: 2em;
}

.playarea {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
}

.hand {
  flex: 1;
  text-align: center;
}

#lefthand {
  background: url(lefthand.png) center center no-repeat;
  background-size: contain;
}

#righthand {
  background: url(righthand.png) center center no-repeat;
  background-size: contain;
}

.hand .chocolate {
  height: 130px;
  margin-top: 80px;
  margin-left: 50px;
  cursor: pointer;
}

